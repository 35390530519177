@import "./variables.css";

.label-and-search-container {
	position: fixed;
	background: var(--primary-color);
	left: 0;
	top: 0;
	margin: 0;
	padding-top: 125px;
	padding-bottom: 10px;
	height: 120px;
	width: 100%;
	z-index: 50;
	display: flex;
	justify-content: center;
}

.label-and-search {
	width: 600px;
	background: var(--secondary-color);
	display: flex;
	justify-content: center;
	align-items: center;
}

.label-and-search h1 {
	margin: 0;
	padding: 5px;
}

.label-and-search input {
	padding: 7px;
	border-radius: 1px;
	border: 2px solid var(--text-color);
	background: var(--primary-color);
	color: var(--text-color);
	width: 300px;
}

@media (max-width: 850px) {
	.label-and-search-container {
		width: 100%;
		padding-top: 90px;
		height: 90px;
	}
	.label-and-search {
		margin: 0;
		padding-left: 0px;
		padding-right: 0px;
	}
}
