@import "./variables.css";

.about-container {
	background: var(--secondary-color);
	height: 100%;
	width: 40%;
	line-height: 1.5;
	padding: 20px;
	display: inline-block;
	vertical-align: top;
	margin-right: 2.5%;
	margin-left: 5%;
}
.about-container p {
	font-size: 1.5em;
}

.autotyper-image-container {
	position: relative;
	width: 100%;
	display: inline-block;
	/* https://stackoverflow.com/a/67440919 */
	mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, transparent 120%);
}
.autotyper-image-container img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.5;
}

.recent-and-autotyper {
	position: relative;
	width: 40%;
	display: inline-block;
	margin-left: 2.5%;
	margin-right: 5%;
}

.recent-projects {
	background: var(--secondary-color);
	height: 20%;
	width: 94.75%;
	line-height: 1.5;
	padding: 20px;
	display: inline-block;
	vertical-align: top;
	margin-bottom: 20px;
}

.recent-projects p {
	font-size: 1.5em;
}

@media (max-width: 1000px) {
	.autotyper-image-container {
		display: none;
	}
	.about-container {
		margin-left: 5%;
		width: 90%;
		box-sizing: border-box;
	}
	.about-container h1 {
		font-family: serif;
	}
	.about-container p {
		font-size: 1em;
	}
	.about-container a {
		font-size: 1em;
		padding: 0;
		margin: 0;
	}
	.recent-and-autotyper {
		width: 90%;
		margin-left: 5%;
		margin-top: 10px;
		box-sizing: border-box;
	}
	.recent-projects {
		height: 100%;
		width: 100%;
		box-sizing: border-box;
	}
	.recent-projects p {
		font-size: 1em;
	}
}
