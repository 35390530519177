@import 'variables.css';

.navbar {
	background: var(--primary-color);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	margin: 0;
	padding: 20px;
	height: 85px;
	z-index: 100;
}

.terminal-prompt {
	color: var(--secondary-color);
	font-size: 30px;
	font-weight: bold;
	margin-right: 10px;
}

h1 {
	color: var(--text-color);
	font-size: 3em;
	margin: 0px;
}

.navbar a {
	color: var(--text-color);
	font-size: 1.5em;
	text-decoration: none;
	margin: 5px;
}

.spacer {
	margin-top: 130px;
	background: orange;
}

@media (max-width: 850px) {
	.navbar {
		height: 40px;
		left: 0;
		right: 0;
		background: var(--secondary-color);
	}

	.navbar a {
		margin: 4px;
	}

	.spacer {
		margin-top: 90px;
	}
	h1 {
		font-family: Ubuntu, monospace;
		font-size: 1em;
		margin: 5px;
		font-weight: bold;
	}
	h2 {
		font-size: 1.2em;
		margin: 5px;
		font-weight: normal;
	}

	a {
		font-size: 1.2em;
		margin: 5px;
	}
	.navbar a {
		font-size: 1em;
	}
	.terminal-prompt {
		display: none;
	}

	.dashes {
		display: none;
	}
}
