.member-container {
	margin: 40px;
}
.member-image-container {
	height: 250px;
	width: 200px;
	display: inline-block;
}
.member-container img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.member-info-container {
	width: 70%;
	display: inline-block;
	vertical-align: top;
	padding: 10px;
}

.member-info-container h1 {
	margin: 0;
	padding: 0;
}
.member-class {
	margin: 5px;
	margin-left: 30px;
}

@media (max-width: 1000px) {
	.member-container {
		width: 70%;
	}
}
