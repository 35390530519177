@import "./variables.css";

.projects-page {
	padding: 20px;
	display: flex;
	justify-content: center;
}

.projects-container {
	margin-top: 130px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.loading {
	display: flex;
	justify-content: center;
}

@media (max-width: 850px) {
	.projects-container {
		margin-top: 90px;
	}
	.projects-page {
		padding: 5px;
	}
}
