@import "./variables.css";
.project-container {
	width: 1000px;
	height: 300px;
}

.project-container .project-image-container img {
	width: 300px;
	height: 200px;
	object-fit: cover;
}

.project-container .project-info-container {
	width: 690px;
	position: relative;
	left: 310px;
	top: -100px;
	display: inline-block;
	background: var(--secondary-color);
	padding: 10px
}

@media (max-width: 850px) {
	.project-container {
		width: 90%;
		margin-top: 20px;
		height: auto;
	}
	.project-container .project-info-container {
		position: static;
		left: 0;
		top: 0;
		box-sizing: border-box;
		width: 300px;
		margin-top: 5px;
	}
}
